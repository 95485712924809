<template>
	<b-card class="shadow-none" no-body>
		<app-collapse accordion type="border">
			<app-collapse-item v-for="page in form.pages" :key="page.id" :title="page.name" class="text-right" style="direction: rtl">
				<template #header>
					<span class="border ml-1 p-50 rounded d-block">
						<feather-icon icon="FileTextIcon" size="20" class="" />
					</span>
					<p class="mb-0 mr-25 text-right flex-fill" style="font-size: 17px">{{ page.name }}</p>
				</template>
				<div v-for="field in page.fields" :key="field.id">
					<field-answer :field-data="field" :answers="submission.answers" />
				</div>
			</app-collapse-item>
		</app-collapse>
	</b-card>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import { BCard } from 'bootstrap-vue';
import FieldAnswer from './field-answer/index.vue';

export default {
	name: 'FormData',

	components: { BCard, AppCollapse, AppCollapseItem, FieldAnswer },

	props: {
		form: {
			type: Object,
			reuired: true,
		},

		submission: {
			type: Object,
			reuired: true,
		},
	},
};
</script>

<style lang="scss">
.question {
	background-color: #f8f8f8;
	padding: 0.5rem 1rem;
	margin-bottom: 0.5rem;
	border-radius: 4px;

	p {
		font-size: 16px;
	}
}

.sub-question {
	background-color: #fff;
	padding: 0.5rem 1rem;
	margin-bottom: 0.5rem;
	border-radius: 4px;

	p {
		font-size: 16px;
	}
}

.collapse-icon [data-toggle='collapse']:after {
	left: 1rem;
	top: 55%;
	right: auto;
}

[class*='collapse-'] .card .card-header {
	padding: 1rem 1rem 1rem 2.8rem;
}
</style>
