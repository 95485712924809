<template>
	<div v-if="!loading" class="mt-2">
		<h5 v-if="showForm" class="mb-1">
			{{ form.name }}
		</h5>
		<div class="d-flex align-items-center mb-3" style="gap: 1rem">
			<b-button v-if="!noBack" variant="flat-secondary" class="cursor-pointer pl-50 pt-50" @click="$emit('goBack')">
				<feather-icon icon="ChevronLeftIcon" class="mr-50" />
				<span class="align-middle">Back</span>
			</b-button>

			<h3 class="mb-0">
				{{ submission.user.name }}
			</h3>
			<b-badge variant="primary" class="font-small-2">
				<span>{{ submission.user.typeName }}</span>
			</b-badge>
			<span>
				{{ submission.created_at.humans }}
			</span>
			<loading-btn :loading="exportPdfLoading" variant="primary" class="ml-auto" @click="exportSubmissionPDF">
				Export PDF
			</loading-btn>
		</div>

		<b-tabs lazy>
			<b-tab title="Data">
				<form-data :form="formData" :submission="submissionData" />
			</b-tab>

			<b-tab>
				<template #title>
					<b-badge variant="primary" pill class="mr-75"> {{ submission.alerts.length }} </b-badge> Alerts
				</template>
				<form-alerts :alerts="submission.alerts" />
			</b-tab>
			<b-tab title="Map">
				<b-card class="shadow-none" border-variant="secondary" no-body>
					<report-map :loc="loc" />
				</b-card>
			</b-tab>
		</b-tabs>
	</div>
	<div v-else class="">
		<div class="text-center text-primary mt-3 mb-2">
			<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
			<span class="d-block mt-1">Loading...</span>
		</div>
	</div>
</template>

<script>
import store from '@/store';
import { BButton, BSpinner, BTabs, BTab, BBadge, BCard } from 'bootstrap-vue';
import api from '@/utils/api';
import LoadingBtn from '@/components/LoadingBtn.vue';

import ReportMap from '../../../ReportMap.vue';
import FormAlerts from './form-alerts.vue';
import FormData from './form-data.vue';

export default {
	name: 'Submission',

	components: { LoadingBtn, BButton, BSpinner, BTabs, BTab, BBadge, BCard, ReportMap, FormAlerts, FormData },

	props: {
		submission: {
			type: Object,
			required: true,
		},
		form: {
			type: Object,
			required: true,
		},
		noBack: {
			type: Boolean,
			default: false,
		},
		showForm: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		formData: undefined,
		submissionData: undefined,
		loc: [],

		loading: false,
		exportPdfLoading: false,
	}),

	created() {
		this.loadData();
	},

	methods: {
		async loadData() {
			try {
				this.loading = true;

				this.formData = await store.dispatch('forms/getForm', { formId: this.form.id, noLoading: true });

				const { data } = await store.dispatch('projects/getFormSubmission', {
					projectId: this.submission.project_id,
					formId: this.form.id,
					submissionId: this.submission.id,
					noLoading: true,
				});

				this.submissionData = data.data;
				this.submissionData.alerts = this.submission.alerts;
				this.loc = [this.submissionData.location.latitude, this.submissionData.location.longitude];
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},

		exportSubmissionPDF() {
			this.exportPdfLoading = true;
			api.getFile(
				`/projects/${this.submission.project_id}/forms/${this.submission.form_id}/submissions/${this.submission.id}/export-pdf`,
				{ withCredentials: false },
			)
				.then((response) => {
					const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'submission-pdf');
					link.setAttribute('target', '__blank');
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => console.log(error))
				.finally(() => {
					this.exportPdfLoading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped></style>
