<template>
	<b-tabs nav-wrapper-class="card type-tabs p-1" pills>
		<b-tab v-for="type in getTypes" :key="type.label" :title="`${type.label}`">
			<form-type :form-type="type" :forms="getTypeForms(type.id)" />
		</b-tab>
	</b-tabs>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import { BTabs, BTab } from 'bootstrap-vue';
import FormType from './form-type.vue';

export default {
	name: 'ProjectFormsIndex',

	components: { BTabs, BTab, FormType },

	data: () => ({
		forms: [],
		loading: false,
	}),

	computed: {
		...mapGetters('attributes', ['getTypes']),
	},

	created() {
		this.fetchForms();
	},

	methods: {
		async fetchForms() {
			this.loading = true;

			try {
				this.forms = await store.dispatch('forms/getProjectForms', this.$route.params.id);
			} catch (error) {
				console.error(error);
			}

			this.loading = false;
		},

		getTypeForms(typeId) {
			return this.forms.filter((f) => f.type === typeId);
		},
	},
};
</script>

<style lang="scss">
.type-tabs > .nav-pills {
	margin-bottom: 0;
}
</style>
