<template>
	<div>
		<div class="position-relative pr-4">
			<h2>{{ form.name }}</h2>
			<p class="card-text mb-3">{{ form.description }}</p>

			<b-modal v-model="settingsModal" title="Form Attributes" size="lg" button-size="sm" centered>
				<form-settings />
			</b-modal>
		</div>
		<b-tabs v-if="!loading" nav-wrapper-class="border-bottom mb-2" nav-class="mb-0" lazy>
			<div>
				<b-tab title="Submissions">
					<form-submissions v-if="form" :form="form" />
				</b-tab>
			</div>
		</b-tabs>
		<div v-else class="">
			<div class="text-center text-primary mt-3 mb-2">
				<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
				<span class="d-block mt-1">Loading...</span>
			</div>
		</div>
	</div>
</template>

<script>
import { BTabs, BTab, BSpinner, VBTooltip, BModal } from 'bootstrap-vue';
import FormSubmissions from './form-submissions.vue';
import FormSettings from './form-settings.vue';

export default {
	name: 'FormReport',

	components: { BSpinner, BTabs, BModal, BTab, FormSubmissions, FormSettings },

	directives: {
		'b-tooltip': VBTooltip
	},

	props: {
		form: {
			type: Object,
			required: true
		},
		type: {
			type: String,
			required: true
		}
	},

	data: () => ({
		loading: false,
		settingsModal: false,
		formData: undefined
	})
};
</script>

<style lang="scss" scoped>
.btn-setting {
	top: 0;
	right: 0;
}
</style>
