<template>
	<div class="border-bottom pb-1" :class="{ 'mr-1 mb-2': !isSubField }">
		<div v-if="fieldData.type === 'DynamicField'" class="">
			<dynamic-answer :field-data="fieldData" :answers="answers" :is-sub-field="isSubField" />
		</div>
		<div v-else-if="fieldData.type === 'ListField'" class="">
			<list-answer :field-data="fieldData" :answers="answers" :is-sub-field="isSubField" />
		</div>
		<div v-else-if="fieldData.type === 'ImageField'" class="">
			<image-answer :field-data="fieldData" :answers="answers" :is-sub-field="isSubField" />
		</div>
		<div v-else class="">
			<h4 class="mb-1 d-flex align-items-center" style="gap: 0.5rem">
				<!-- <feather-icon v-if="!isSubField" icon="CircleIcon" class="ml-50" size="14" /> -->
				<span class="mr-2">- {{ fieldData.question }}</span>
			</h4>
			<div class="mr-2 p-1 rounded answer">
				<span> {{ getAnswer(fieldData.id) }} </span>
			</div>
		</div>
	</div>
</template>

<script>
import DynamicAnswer from './dynamic-answer.vue';
import ImageAnswer from './image-answer.vue';
import ListAnswer from './list-answer.vue';

export default {
	name: 'FieldAnswer',

	components: { DynamicAnswer, ListAnswer, ImageAnswer },

	props: {
		fieldData: {
			type: Object,
			required: true,
		},

		answers: {
			type: Array,
			default: () => [],
		},

		isSubField: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		getAnswer(fieldId) {
			const answer = this.answers.find((answer) => answer.field_id === fieldId)?.answer;
			return answer && answer.trim().length !== 0 ? answer : 'لم تتم الإجابة على هذا السؤال';
		},
	},
};
</script>

<style lang="scss">
.answer {
	background-color: #f9f9f9;
}
</style>
