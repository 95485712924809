<template>
	<div>
		<h4 class="mb-1 d-flex align-items-center" style="gap: 0.5rem">
			<span class="mr-2">- {{ fieldData.question }}</span>
		</h4>
		<div v-if="!groups.length" class="mr-2 p-1 rounded answer">
			لم تتم الإجابة على هذا السؤال
		</div>
		<div v-else class="position-relative table-responsive">
			<table class="table b-table">
				<thead>
					<tr>
						<th v-for="subField in subFields" :key="subField.id">{{ subField.question }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="group in groups" :key="group">
						<td v-for="subField in subFields" :key="`${subField.id}-${group}`">
							{{ getAnswer(subField.id, group) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DynamicAnswer',

	props: {
		fieldData: {
			type: Object,
			required: true,
		},

		answers: {
			type: Array,
			default: () => [],
		},

		isSubField: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		subFields() {
			return this.fieldData.form.pages[0]?.fields ?? [];
		},

		subAnswers() {
			const subFieldIds = this.subFields.map((f) => f.id);
			return this.answers.filter((answer) => subFieldIds.includes(answer.field_id));
		},

		groups() {
			const groups = new Set(this.subAnswers.map((answer) => answer.group_id));
			return groups.size > 0 ? Array.from(groups) : [];
		},
	},

	methods: {
		getAnswer(id, group) {
			const answer = this.subAnswers.filter((answer) => answer.field_id === id && answer.group_id === group)[0];
			return answer && answer.answer ? answer.answer : 'لم تتم الإجابة على هذا السؤال';
		},
	},
};
</script>

<style lang="scss" scoped></style>
