<template>
	<div class="">
		<b-tabs
			v-if="!!forms.length"
			vertical
			content-class="col-12 col-md-9 mt-1 mt-md-0"
			pills
			nav-wrapper-class="col-md-3 col-12"
			nav-class="nav-left"
			lazy
		>
			<b-tab v-for="form in forms" :key="form.id">
				<template #title>
					<span class="text-ellipsis">{{ form.name }}</span>
				</template>
				<b-card>
					<FormReport :type="formType.label" :form="form" />
				</b-card>
			</b-tab>
		</b-tabs>
		<div v-else class="text-muted text-center">
			<div class="pt-3">
				<feather-icon icon="AlertCircleIcon" size="56" />
				<p class="mb-0 mt-2">
					There's no <strong>{{ formType.label }}</strong> forms attached to this project!
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue';
import FormReport from './form-report/Index';

export default {
	name: 'FormType',

	components: {
		BCard,
		BTabs,
		BTab,

		FormReport,
	},

	props: {
		formType: {
			type: Object,
			required: true,
		},
		forms: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style lang="scss"></style>
