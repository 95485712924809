<template>
	<div>
		<h4 class="mb-1 d-flex align-items-center" style="gap: 0.5rem">
			<span class="mr-2">- {{ fieldData.question }}</span>
		</h4>
		<div class="d-flex answer p-50 mr-2" :class="{ 'p-1 answer': !isSubField }" style="gap: 1rem">
			<div v-if="!getMedia(fieldData.id).length">
				لم تتم الإجابة على هذا السؤال
			</div>
			<div v-for="img in getMedia(fieldData.id)" :key="img" class="flex-shrink-1">
				<img :src="img" thumbnail class="image img-thumbnail" @click="openImage(img)" />
			</div>
		</div>

		<b-modal v-model="showImage" title="Attachment" centered hide-footer>
			<img :src="selectedImage" class="d-block img-thumbnail w-100" />
		</b-modal>
	</div>
</template>

<script>
import { BModal } from 'bootstrap-vue';

export default {
	name: 'ImageAnswer',

	components: { BModal },

	props: {
		fieldData: {
			type: Object,
			required: true,
		},

		answers: {
			type: Array,
			default: () => [],
		},

		isSubField: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		showImage: false,
		selectedImage: null,
	}),

	methods: {
		getMedia(fieldId) {
			return this.answers.find((answer) => answer.field_id === fieldId)?.media ?? '';
		},

		openImage(img) {
			this.selectedImage = img;
			this.showImage = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.image {
	display: block;
	max-width: 150px;
	max-height: 150px;
	width: auto;
	height: auto;
	cursor: pointer;
}
</style>
