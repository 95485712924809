<template>
	<div>
		<h4 class="mb-1 d-flex align-items-center" :class="{ 'has-alert': alert }" style="gap: 0.5rem">
			<span class="mr-2">- {{ fieldData.question }}</span>
		</h4>
		<div class="rounded answer" :class="{ 'mr-2 p-1': !isSubField }">
			<span class="d-block" :class="{ 'p-1': isSubField }"> {{ getAnswer(fieldData.id) }} </span>
		</div>
		<p v-if="skipOptions.length" class="mr-2 mb-0 pt-1">الاسئلة الفرعية</p>
		<div v-for="(o, i) in skipOptions" :key="o.id">
			<div v-for="subField in o.skipPages.fields.pages[0].fields" :key="subField.id" class="sub-question" :class="{ 'mt-1': i > 0 }">
				<field-answer :field-data="subField" :answers="answers" is-sub-field />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ListAnswer',

	components: { FieldAnswer: () => import('./index.vue') },

	props: {
		fieldData: {
			type: Object,
			required: true,
		},

		answers: {
			type: Array,
			default: () => [],
		},

		isSubField: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		alert: true,
	}),

	computed: {
		skipOptions() {
			const answer = this.answers.find((answer) => answer.field_id === this.fieldData.id);
			return this.fieldData.options.filter((o) => !!o.skipPages).filter((o) => o.id === answer?.option_id);
		},
	},

	created() {
		this.alert = this.getAnswer(this.fieldData.id)?.alert;
	},

	methods: {
		getAnswer(fieldId) {
			const answer = this.answers
				.find((answer) => answer.field_id === fieldId)
				?.options.map((item) => item.answer)
				.join(', ');

			return answer && answer.trim().length !== 0 ? answer : 'لم تتم الإجابة على هذا السؤال';
		},
	},
};
</script>

<style lang="scss" scoped>
.sub-question {
	margin-right: 1.5rem;
}

.has-alert {
	border: 2px solid red;
}
</style>
