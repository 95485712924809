<template>
	<div>
		<base-table v-if="!details" :headers="headers" :items="getSubmissions" paginate table-top class="border-secondary">
			<template #cell(alerts)="{ value }">
				<b-badge :variant="value.length ? 'light-danger' : 'light-primary'"> {{ value.length }} Alerts </b-badge>
			</template>
			<template #cell(actions)="{ item }">
				<b-button
					v-b-tooltip.hover.top="'View'"
					variant="outline-secondary"
					class="cursor-pointer"
					size="sm"
					@click="showDetails(item)"
				>
					<span class="align-middle">View</span>
				</b-button>
			</template>
		</base-table>

		<div v-else>
			<Submission :submission="currentSubmit" :form="form" @goBack="goBack" />
		</div>
	</div>
</template>

<script>
import { BBadge, BButton, VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import Submission from './submission/Index.vue';

export default {
	name: 'FormSubmissions',

	components: { BBadge, BButton, Submission, BaseTable },

	directives: {
		'b-tooltip': VBTooltip,
	},

	props: {
		form: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		headers: [
			{ key: 'id' },
			{ key: 'user.name', label: 'Username', sortable: false },
			{ key: 'created_at.humans', label: 'Date', sortable: false },
			{ key: 'alerts', label: 'Alerts', sortable: false },
			{ key: 'actions' },
		],

		details: false,
		currentSubmit: undefined,
		formData: undefined,

		submissions: [],
	}),

	methods: {
		async showDetails(item) {
			this.currentSubmit = item;
			this.details = true;
		},

		goBack() {
			this.details = false;
			this.currentSubmit = undefined;
		},

		async getSubmissions(ctx, searchQuery) {
			try {
				const { data } = await store.dispatch('projects/getFormSubmissions', {
					projectId: this.$route.params.id,
					formId: this.form.id,
					options: { ...ctx, searchQuery },
				});

				return data;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
