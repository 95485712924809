import { render, staticRenderFns } from "./dynamic-answer.vue?vue&type=template&id=2b190de0&scoped=true&"
import script from "./dynamic-answer.vue?vue&type=script&lang=js&"
export * from "./dynamic-answer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b190de0",
  null
  
)

export default component.exports