<template>
	<b-card class="shadow-none" border-variant="secondary" no-body>
		<base-table :headers="headers" :items="alerts" paginate table-top has-select @selectChange="setSelected"></base-table>
		<div class="d-flex m-1 justify-content-end">
			<b-button variant="primary" :disabled="!selected.length" @click="responseModal = true">Response</b-button>
		</div>

		<b-modal v-model="responseModal" centered title="Reopnse to alerts">
			<b-form>
				<b-form-group label="Action">
					<b-form-textarea></b-form-textarea>
				</b-form-group>

				<b-form-group label="Date">
					<b-input-group>
						<b-form-input type="text" placeholder="YYYY-MM-DD" autocomplete="off" show-decade-nav />
						<b-input-group-append>
							<b-form-datepicker show-decade-nav button-only right locale="en-US" />
						</b-input-group-append>
					</b-input-group>
				</b-form-group>

				<b-form-group label="Status">
					<v-select></v-select>
				</b-form-group>

				<b-form-group label="Send it to">
					<v-select></v-select>
				</b-form-group>
			</b-form>
		</b-modal>
	</b-card>
</template>

<script>
import vSelect from 'vue-select';
import { BCard, BButton, BModal, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend, BFormDatepicker } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';

export default {
	name: 'FormAlerts',

	components: {
		BCard,
		BButton,
		BModal,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		BInputGroup,
		BInputGroupAppend,
		BFormDatepicker,
		BaseTable,
		vSelect,
	},

	props: {
		alerts: {
			type: Array,
			default: () => [],
		},
	},

	data: () => ({
		headers: [
			{ key: 'id' },
			{ key: 'question', sortable: true },
			{ key: 'answer', sortable: true },
			{ key: 'created_at.humans', label: 'date', sortable: true },
		],

		responseModal: false,

		selected: [],
	}),

	methods: {
		setSelected(val) {
			this.selected = val;
		},
	},
};
</script>

<style lang="scss" scoped></style>
