<template>
	<b-form>
		<validation-observer ref="simpleRules">
			<b-form-group label="Filled by" label-for="filler">
				<validation-provider #default="{ errors }" name="Type" rules="required">
					<v-select
						id="filler"
						v-model="formData.filler"
						:clearable="false"
						:filterable="false"
						:searchable="false"
						:options="fillers"
						:reduce="(o) => o.id"
						:state="errors.length > 0 ? false : null"
					/>
					<small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
			</b-form-group>

			<b-form-group label="Name" label-for="name">
				<validation-provider #default="{ errors }" name="Name" rules="required">
					<b-form-input id="name" v-model="formData.name" :state="errors.length > 0 ? false : null" placeholder="Name" />
					<small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
			</b-form-group>

			<b-form-group label="Repeat" label-for="period">
				<validation-provider #default="{ errors }" name="Type" rules="required">
					<v-select
						id="period"
						v-model="formData.period"
						:clearable="false"
						:filterable="false"
						:searchable="false"
						:options="periods"
						:reduce="(o) => o.id"
						:state="errors.length > 0 ? false : null"
					/>
					<small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
			</b-form-group>
		</validation-observer>
	</b-form>
</template>

<script>
import { BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';

export default {
	name: 'FormSettings',

	components: {
		BForm,
		BFormGroup,
		BFormInput,
		vSelect,
		ValidationProvider,
		ValidationObserver,
	},

	data: () => ({
		formData: {
			name: '',
			filler: '',
			period: '',
		},

		fillers: [
			{ id: 1, label: 'Ali 1' },
			{ id: 2, label: 'Ali 2' },
			{ id: 3, label: 'Ali 3' },
			{ id: 4, label: 'Ali 4' },
		],

		periods: [
			{ id: 1, label: 'Daily' },
			{ id: 2, label: 'Weekly' },
			{ id: 3, label: 'Monthly' },
		],
	}),
};
</script>

<style lang="scss" scoped></style>
